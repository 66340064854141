/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    br: "br"
  }, _provideComponents(), props.components), {HrefLangManager, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/'], ['en', 'https://www.hear.com/hearing-aids/brands/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-the-best-hearing-aid-brand",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-the-best-hearing-aid-brand",
    "aria-label": "find the best hearing aid brand permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find the Best Hearing Aid Brand"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Canadian market has over 1,000 different hearing aids from the leading manufacturers. Our mission at hear.com, along with our comprehensive network of partner providers, is to help you find the right hearing aid. To help you in your search, we have put together more information on some of the best hearing aid brands, including ReSound, Signia, Starkey, and Widex. Simply click on the brand to learn more about its available models and their specifications."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you in need of hearing aids? Would you like to learn more about the top hearing aid brands? Then contact us! Our hearing aid experts will advise you on all the different hearing aid options, completely free of charge. All our consultations are non-binding and unbiased, meaning our services are risk-free and tailored to your specific needs. In collaboration with a hearing aid provider in your area, we offer the best hearing aid brands at affordable prices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Call us today. We are available Monday to Saturday, from 8:00am to 8:00pm. You can reach us by phone at 647-492-5458. We look forward to hearing from you soon."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-is-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-is-here-to-help",
    "aria-label": "hearcom is here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com Is Here to Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This was just an overview of some of the latest hearing aids by the leading manufacturers. For more information about hearing aids, pricing, and financing options, give us a call or complete our online questionnaire to speak with one of our hearing aid experts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We at hear.com, along with our network of 200+ Partner Providers, are here to guide you on the path to better hearing. We carry all the latest hearing aids from all the top brands, so rest assured, you’ll receive a high quality product at an affordable price. Not only that, you’ll enjoy a 30-day risk-free trial period to wear, test, and fall in love with your new devices and improved quality of life."), "\n", React.createElement(ButtonCta, {
    copy: "Start no-risk trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "overview-of-hearing-aid-manufacturers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview-of-hearing-aid-manufacturers",
    "aria-label": "overview of hearing aid manufacturers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overview of hearing aid manufacturers:"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-signia.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/",
    className: "c-md-a"
  }, "Signia")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Piscataway, NJ\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Signia is the latest hearing aid brand, co-branded with Siemens, that uses the new primax technology to reduce listening effort in any environment. Signia primax builds on the successful binax platform by providing a true binaural experience that redefines ease of listening.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-widex.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/widex/",
    className: "c-md-a"
  }, "Widex")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1956"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Lynge, Denmark\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Restoring natural hearing is paramount for Widex. To this end, the Danish manufacturer creates innovative, high quality hearing aids that bring newfound joy to the lives of people with hearing loss. The world’s first fully digital hearing aid is a testament to Widex’s spirit of innovation.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-resound.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/",
    className: "c-md-a"
  }, "ReSound")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1943"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Ballerup, Denmark\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Using innovative hearing solutions, ReSound guides people of all ages back into the world of sound. Since 1943, this Danish company has been developing high-quality hearing devices that allow their wearers to forget they have hearing loss.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-bernafon.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/bernafon/",
    className: "c-md-a"
  }, "Discover Bernafon"), "\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Hearing and understanding are basic needs of every individual. With Swiss innovation and high-quality products, Bernafon is uniquely situated to help people with hearing loss regain their quality of life and improve communication with family and friends. Bernafon hearing aids use state-of-the-art hearing technology to compensate for hearing loss while providing additional personal benefits like wireless connectivity for hands-free use of your mobile phone.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-starkey.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/",
    className: "c-md-a"
  }, "Starkey")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Eden Prairie, MN\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Starkey Hearing Technologies is much more than the hearing aids they produce. They are in the business of connecting people and changing lives. They believe that being able to hear the world and the people around us is as essential to the human experience as breathing.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-unitron.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/unitron/",
    className: "c-md-a"
  }, "Unitron")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Kitchener, ON, Canada\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "The ability to hear greatly affects one’s quality of life. Unitron’s state-of-the-art hearing aids help restore a higher quality of life for those with hearing loss. Unitron aims to provide uncompromising speech comprehension and natural hearing.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
